import React, { useState, useEffect } from 'react'
import './Home.css';
import { useSelector, useDispatch } from "react-redux"
import { getProductsLists } from "./../Loja/products"
import { getPostsLists } from '../../redux/blogReducer';
import chapati from './../../assets/images/pratos/chapati.jpg'
import feijoada from './../../assets/images/pratos/feijoada.jpg'
import arroz from './../../assets/images/pratos/arroz-pilao.jpg'
import kachori from './../../assets/images/pratos/kachori.jpeg'
import kuku from './../../assets/images/pratos/kuku.jpg'
import mafe from './../../assets/images/pratos/mafe-veg.jpg'
import moqueca from './../../assets/images/pratos/moqueca.jpg'
import ndeke from './../../assets/images/pratos/ndeke.jpg'
import DOMPurify from 'dompurify';
import Footer from './../../composant/Footer/Footer'
import sliderData from '../../data/sliderData';
import Data from '../../data/data'
import Pitchou from './../Admin/img/people.png'
import NavMenu from '../../composant/navMenu/NavMenu';
import Slider from '../../composant/Slider/Slider';
import Orcamento from '../../composant/orcamento/Orcamento';
import Loja from '../../data/loja';
import { Link } from 'react-router-dom';
import leftChevron from './../../assets/images/SVG/left-arrow.svg'
import rightChevron from './../../assets/images/SVG/right-arrow.svg'



export default function Home() {

    const dispatch = useDispatch()
    const products = useSelector(state => state.products)
    const LIMIT = 300;
    console.log(products.items)
    const productsUnicos = [];

    let content
    if (!products.items && !products.loading && !products.error) {
        dispatch(getProductsLists())
    }
    else if (products.loading) {
        //content = <img src={spinner} alt='spinning loader'/>
    }
    else if (products.error) {
        content = <p> An error has occured</p>
    }
    else if (products.items) {
        Object.values(products.items).forEach((item) => {
            var duplicated = productsUnicos.findIndex(redItem => {
                return item.name === redItem.name;
            }) > -1
            if (!duplicated) {
                productsUnicos.push(item);
            }

        });
    }
    const posts = useSelector(state => state.blogReducer)

    const postsUnicos = [];


    if (!posts.items && !posts.loading && !posts.error) {
        dispatch(getPostsLists())
    }
    else if (posts.loading) {
        //content = <img src={spinner} alt='spinning loader'/>
    }
    else if (posts.error) {
        content = <p> An error has occured</p>
    }
    else if (posts.items) {
        Object.values(posts.items).forEach((item) => {
            var duplicated = postsUnicos.findIndex(redItem => {
                return item.titre_post === redItem.titre_post;
            }) > -1
            if (!duplicated) {
                postsUnicos.push(item);
            }

        });
    }
    const selectCarousel = (e) => {
        const boxs = document.querySelectorAll(".box-container")
        document.querySelector('#imagem-caroussel').classList.add('active');
        const box = e.currentTarget
        const image = box.children[2]
        const title = box.children[4]
        const content = box.children[5]
        const imageContainer = document.querySelector('#imagem-caroussel .box >img')
        const titreContainer = document.querySelector('#imagem-caroussel .box >h3')
        const contentContainer = document.querySelector('#imagem-caroussel .box >p')
        imageContainer.src = image.src
        titreContainer.innerHTML = title.innerText
        contentContainer.innerHTML = content.innerText
    }
    const selectCloseCarousel = () => {
        document.querySelector('#imagem-caroussel').classList.remove('active');
    }
    const ContDirection = document.querySelectorAll(".nav-product");
    const handleLeft = (e) => {
        e.preventDefault();
        ContDirection.forEach((cont) => {
            if (e.target.dataset.container === cont.dataset.container) {
                cont.scrollLeft -= cont.offsetWidth;
            }

        })

    }
    const handleRight = (e) => {
        e.preventDefault();
        ContDirection.forEach((cont) => {
            if (e.target.dataset.container === cont.dataset.container) {
                cont.scrollLeft += cont.offsetWidth;
            }

        })

    }

    function createMarkup(html) {
        console.log(html.substr(0, LIMIT))
        return {
            __html: DOMPurify.sanitize(html.substr(0, LIMIT))

        }
    }
    return (
        <>

            <NavMenu />

            <Slider />
            <section className="diches" id='dishe'>
                <h3 className="sub-heading">Nossos pratos</h3>
                <h1 className="heading">Pratos popular</h1>
                <div className="box-container" data-carossel="carossel">
                    {(Object.values(sliderData).map(prato =>


                        <div className="box" key={prato.id} onClick={selectCarousel}>
                            <a href="#" className='heart'>
                                <i class="fa-solid fa-heart"></i>
                            </a>
                            <a href="#" className='eye'>
                                <i class="fa-solid fa-eye"></i>
                            </a>
                            <img src={prato.imagem} alt="" />
                            <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star-half-alt"></i>
                            </div>
                            <h3>{prato.titre}</h3>
                            <p>{prato.description}</p>
                        </div>

                    ))}
                    <div id="imagem-caroussel">
                        <div className="box">
                            <h3></h3>
                            <img src="" alt="" />
                            <p>´teste</p>

                        </div>
                        <i className="fas fa-times" id="close" onClick={selectCloseCarousel}></i>
                    </div>

                </div>
            </section>
            <section className='about' id='about'>
                <h3 className="sub-heading">Congolinaria</h3>
                <h1 className="heading">Restaurantes</h1>
                <div className="row">
                    <div className="image">
                        <img src={ndeke} alt="" />
                    </div>
                    <div className="content">
                        <h3>CONGOLINARIA ZO </h3>
                        <p>AVENIDA PROFESSOR ALFONSO BOVERO , PROXIMO AO METRÔ SUMARÉ , LINHA VERDE / SP</p>
                        <p>
                            CONGOLINARIA DO ALFONSO BOVERO é a nossa unidade tradicional. Desde o início de 2017 com uma loja fixa, cravamos o congolinaria na avenida  no andar de cima da fatiado discos, já  virou uma  referência do turismo, moda e gastronomia congolesa em são paulo.

                        </p>
                        <p>O horário de terça a domingo é das 11h às 22h.</p>

                        <h3>CONGOLINARIA ZL </h3>
                        <p>RUA CAQUITO 251, PROXIMO AO MERCADO MUNICIPAL PENHA / SP</p>
                        <p>O  CONGOLINARIA DA RUA CAQUITO é nossa primeira unidade na Zona Leste, uma verdadeira reprodução da experiência do Alfonso. A mesma atmosfera, mesmo cardápio, mesmo atendimento, com um salão, onde acontece mensalmente os bailes e com DJs convidados. Nossa unidade da penha  oferece nosso cardápio completo rodízio , a la carte, drinks, sucos tipicos e sobremesas.
                        </p>
                        <p> O horário de terça a sábado é das 11h às 22h e Domingo das 11h às 17h.. Entrega pelo Ifood .</p>
                        <div className="icons-container">
                            <div className="icons">
                                <i class="fas fa-shipping-fast"></i>
                                <span>DELIVERY DE COMIDA CONGOLESA</span>
                            </div>
                            <div className="icons">
                                <i class="fa-solid fa-utensils"></i>
                                <span>RODIZIO DE COMIDA CONGOLESA</span>
                            </div>
                            <div className="icons">
                                <i class="fas fa-headset"></i>
                                <span>FUNCIONAMENTO: terça a domingo  das 11h às 22h.</span>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section className="menu" id="menu">
                <h3 className="sub-heading">Nossa Loja</h3>
                <h1 className="heading">
                    Produtos que pode levar para casa
                </h1>
                <div className='contenair-product'>
                    <div className="nav-product" >


                        {(Object.values(productsUnicos).map(produto =>
                            <div className="box">
                                <div className="image">
                                    <img src={produto.images?.[0]?.src} alt="" />
                                    <a href="#" className="heart">
                                        <i class="fa-solid fa-heart"></i>
                                    </a>
                                </div>
                                <div className="content">
                                    <h3>{produto.name}</h3>
                                </div>
                            </div>
                        ))}

                    </div>
                    <button className='navigation-button-product prev-button' onClick={handleLeft}>
                        <img src={leftChevron} alt="" />
                    </button>
                    <button className="navigation-button-product next-button" onClick={handleRight} >
                        <img src={rightChevron} alt="next image" />
                    </button>
                </div>

            </section>
            <section className="menu" id="menu">
                <h3 className="sub-heading">Blog</h3>
                <h1 className="heading">
                    Acompanha nosso blog
                </h1>
                <div className='contenair-product'>
                    <div className="nav-product" >


                        {(Object.values(postsUnicos).map(post =>
                            <div className="box">
                                <Link to={
                                    `/single_blog/${post.titre_post.replace(/\s+/g, '-')}`
                                }

                                    state={{
                                        titre: post.titre_post,
                                        resumo: post.resumo_post,
                                        texto: post.texto_post,
                                        imagem: post.imagem_post,
                                        id_post: post.id_post,
                                        date_created: post.dateCreated_post

                                    }}
                                >
                                    <div className="image">
                                        <img id="logo" src={"https://congolinaria.com.br/" + post.imagem_post} alt="Congolinaria" />
                                    </div>
                                    <div className="content">
                                        <h3 key={post.id_post}>{post.titre_post} </h3>
                                    </div>

                                </Link>
                                <div
                                    className='titre-post' key={post.id_post}
                                    dangerouslySetInnerHTML={createMarkup(post.resumo_post)}>

                                </div>
                            </div>
                        ))}

                    </div>
                    <button className='navigation-button-product prev-button' onClick={handleLeft}>
                        <img src={leftChevron} alt="" />
                    </button>
                    <button className="navigation-button-product next-button" onClick={handleRight} >
                        <img src={rightChevron} alt="next image" />
                    </button>
                </div>

            </section>


            <section className="review" id="review">
                <div className="review-slider">
                    <div className="wrapper">

                        <div className="slide">
                            <i className="fas fa-quote-right"></i>
                            <div className="user">
                                <img src={Pitchou} alt="" width={100} />
                                <div className="user-info">
                                    <h3>Pitchou Luambo</h3>
                                    <span>Chef do congolinaria</span>
                                    <div className="stars">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star-half-alt"></i>
                                    </div>
                                </div>
                            </div>
                            <p>“Congolinária é a possibilidade de mostrar para as pessoas a história que os brasileiros desconhecem.
                                Falamos da cultura africana (da congolesa, principalmente),
                                da gastronomia, dos direitos dos animais, da situação dos refugiados…
                                Os visitantes entram, comem e saem diferentes”</p>
                        </div>
                    </div>
                </div>
            </section>
            <Orcamento />
            <Footer />
        </>
    )
}
