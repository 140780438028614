import './Cardapio.css'
import React, { useEffect, useState } from 'react'
import Footer from '../../composant/Footer/Footer'
import NavMenu from '../../composant/navMenu/NavMenu';
import Slider from '../../composant/Slider/Slider';
import NavCardapio from '../../composant/navCardapio/NavCardapio';
import FormSearch from '../../composant/FormSearch';


export default function Cardapio() {
  // dias da semana  automatico
  let agora = new Date;
  let diaSem = agora.getDay()
  const getdia = () => {
    switch (diaSem) {
      case 0:
        return "Domingo"
      case 1:
        return "Segunda-Feira"
      case 2:
        return "Terça-Feira"
      case 3:
        return "Quarta-Feira"
      case 4:
        return "Quinta-Feira"
      case 5:
        return "Sexta-Feira"
      case 6:
        return "Sábado"

    }
  }
  console.log(getdia())
  useEffect(() => {
    let boxs = document.querySelectorAll("section.rodizio .box-container .box")
    boxs.forEach(box => {
      box.classList.remove("active")
      if (box.dataset.dia === getdia()) {
        box.classList.add("active")
      }
    })

  }, [])
  return (
    <>
      <NavMenu />

      <NavCardapio />
      <Slider />


      <section className="cardapio" id="bebidas">


        <h3 className="sub-heading">Bebidas</h3>

        <h1 className="heading">bebidas</h1>

        <div className="box-container" >
          <h3 className="heading">Sucos típicos</h3>
          <div className="box" >
            <h3>Tangawisi </h3>
            <p>
              Suco a base de gengibre, abacaxi e limão
            </p>
            <span>R$ 12.00</span>
          </div>

          <div className="box" >
            <h3>Bissap </h3>
            <p>
              Suco a base de flor de hibisco com manga
            </p>
            <span>R$ 12.00</span>
          </div>
          <div className="box" >
            <h3>Tomi </h3>
            <p>
              Suco a base de  tamarindo e manga
            </p>
            <span>R$ 12.00</span>
          </div>

        </div>

        

        
        
      </section>
      <section className="cardapio" id="entradas">

        <h3 className="sub-heading">Salgados</h3>

        <div className="box-container" >
          <div className="box" >
            <h3>Sambusas</h3>
            <p>Pastelzinhos típicos africanos, recheados com sabores variados de vegetais (assado)</p>

            SABORES DISPONIVEIS (palmitos, abobrinhas, berinjela, espinafre com semente de girassol, jaca)

            <span>R$ 6,00 unidade</span>
           
          </div>

          <div className="box" >
            <h3>ESFIHA ABERTA</h3>
            <p>Esfiha aberta. (assado)</p>

            SABORES DISPONIVEIS (palmitos, abobrinhas, berinjela, espinafre com semente de girassol, jaca)

            <span>R$ 10,00 unidade</span>
           
          </div>
          
          <div className="box">
            <h3>KACHORI</h3>
            <p>Bolinhos feito à base de batatinha  inglesa, shimeji e gengibre (frito).</p>

            <span>R$ 5,00 unidade</span>
            
          </div>
          
          <div className="box">
            <h3>Acarajé</h3>
            <p>Acarajé africano, servido com quiabo refogado na pasta de amendoim, tomate fresco e especiarias.</p>

            <span>R$ 25,00</span>
          </div>

        </div>
      </section>
      <section className="cardapio" id="pratos">
        <h3 className="sub-heading">Pratos</h3>

        <div className="box-container" data-carossel="carossel">
          <div className="box">
            <h3>SOKOMUTU (Somente às Sextas-Feiras)</h3>

            <p>Moqueca de Banana da Terra, acompanha arroz com coco e alho poró, farofa de amendoim.</p>

            <span>R$ 39,90</span>
           
          </div>
          <div className="box">
            <h3>NGOMBE </h3>

            <p>Nhoque de banana da terra, com molho de tomates frescos e shimeji.</p>



            <span>R$ 39,90</span>
           
          </div>
          
          <div className="box">
            <h3>SIMBA</h3>

            <p>Arroz Pilao (com vegetais cozido em especiarias e suco de gengibre) couve na mwamba e kachori.</p>

            <span>R$ 39,90</span>
            
          </div>
          

        </div>

      </section>
      <section className="cardapio" id="sobremesas">
        <h3 className="sub-heading">Doces</h3>

        <div className="box-container" >
          <div className="box">
            <h3>Sambusa au Chocolat</h3>
            <p>pastelzinho assado recheado com o Chocolate Amargo NXT 55,7% -  CALLEBAUT.</p>

            <span>R$ 8,00</span>
            
          </div>

        </div>

      </section>
      
      <Footer />
    </>
  )
}
