import './Cardapio.css'
import React, { useEffect, useState } from 'react'
import Footer from '../../composant/Footer/Footer'
import NavMenu from '../../composant/navMenu/NavMenu';
import Slider from '../../composant/Slider/Slider';
import NavCardapio from '../../composant/navCardapio/NavCardapio';
import FormSearch from '../../composant/FormSearch';


export default function CardapioCongolinaria() {
  // dias da semana  automatico
  let agora = new Date;
  let diaSem = agora.getDay()
  const getdia = () => {
    switch (diaSem) {
      case 0:
        return "Domingo"
      case 1:
        return "Segunda-Feira"
      case 2:
        return "Terça-Feira"
      case 3:
        return "Quarta-Feira"
      case 4:
        return "Quinta-Feira"
      case 5:
        return "Sexta-Feira"
      case 6:
        return "Sábado"

    }
  }
  console.log(getdia())
  useEffect(() => {
    let boxs = document.querySelectorAll("section.rodizio .box-container .box")
    boxs.forEach(box => {
      box.classList.remove("active")
      if (box.dataset.dia === getdia()) {
        box.classList.add("active")
      }
    })

  }, [])
  return (
    <>
      <NavMenu />

      <NavCardapio />
      <Slider />

      {/* Pratos Fixos */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Pratos Fixos</h2>
        <ul className="space-y-1">
          <li><strong>NGOMBE:</strong> Nhoque de banana da terra com molho de tomate fresco e shimeji – R$ 30</li>
          <li><strong>MBUZI:</strong> Fufu de milho ou arroz, couve na mwamba e banana da terra frita – R$ 30</li>
          <li><strong>SIMBA:</strong> Arroz Pilao, couve na mwamba e kachori – R$ 28</li>
        </ul>
      </section>

      {/* Prato do Dia */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Prato do Dia</h2>
        <ul className="space-y-1">
          <li><strong>Terça-feira:</strong> Mbika na Madesu - R$ 35</li>
          <li><strong>Quarta-feira:</strong> Feijoada do Chef - R$ 32</li>
          <li><strong>Quinta-feira:</strong> Kuku - R$ 30 </li>
          <li><strong>Sexta-feira:</strong> Sokomutu - R$ 35</li>
          <li><strong>Sábado:</strong> Feijoada do Chef - R$ 32</li>
          <li><strong>Domingo:</strong> Bata - R$ 35</li>
        </ul>
      </section>

      {/* Entradas */}
      <section className="mb-6">
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Entradas</h2>
        <ul className="space-y-1">
          <li><strong>Sambusas (6un):</strong> Pastelzinhos africanos recheados com vegetais (sabores: palmitos, abobrinhas, berinjela, espinafre, jaca temperado) – R$ 25</li>
          <li><strong>Kachori (6un):</strong> Bolinhos de batata inglesa com shimeji e gengibre – R$ 25</li>
          <li><strong>Banana da Terra Frita:</strong> Com pasta de amendoim – R$ 18</li>
          <li><strong>Bitumbula:</strong> Acarajé africano com quiabo refogado na pasta de amendoim – R$ 18</li>
        </ul>
      </section>

     {/* Sobremesas */}
     <section className="mb-6">
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Sobremesas</h2>
        <p><strong>Pudim de Mandioca Fermentada:</strong> Com leite de coco e calda de manga – R$ 16</p>
      </section>
    {/* Bebidas Naturais */}
    <section className="cardapio" id="bebidas">
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Bebidas Naturais – R$ 10</h2>
        <ul className="space-y-1">
          <li><strong>Tangawisi:</strong> Gengibre, abacaxi e limão</li>
          <li><strong>Bissap:</strong> Hibisco com manga</li>
          <li><strong>Tomi:</strong> Tamarindo com manga</li>
        </ul>
      </section>

      {/* Drinks da Casa */}
      <section>
        <h2 className="text-2xl font-semibold border-b border-[#442c1d] pb-1 mb-2">Drinks da Casa – R$ 27</h2>
        <ul className="space-y-1">
          <li><strong>Tangawisi Drink:</strong> Vodka ou cachaça, suco de tangawisi, água com gás e xarope de gengibre</li>
          <li><strong>Bissap Drink:</strong> Vodka ou cachaça, suco de bissap, água com gás e xarope de hibisco</li>
          <li><strong>Tomi Drink:</strong> Vodka ou cachaça, suco de tomi, água com gás e xarope de tamarindo</li>
        </ul>
      </section>
      
      <section className="rodizio" id="rodizio">
        <h3 className="sub-heading">Menu  rodízio {getdia()}</h3>
        <div className="box-container" >
          <div className="box active" data-dia="Terça-Feira">
            <h3>Terça- feira</h3>
            <p>– Acarajé com quiabo temperado  na pasta de amendoim</p>

            <p>– arroz com coco</p>

            <p>– macarrão de arroz ( sem glúten) com creme branco de sementes de girassol e shimeji</p>

            <p>– Kwanga (massa cozida de mandica fermentada) com madesu (  Feijão branco temperado no dendê, tomate fresco e especiarias).</p>

            <p>– Sambusas : Pasteizinhos típicos africanos, recheados com vegetais. (assado)</p>

            <p>– kachori : Bolinhos feito à base de batatinha de inglesa, shimeji e gengibre.  (frito).</p>

            <p>– fufu ( polenta tipica a base de fubá e água) com couve na mwamba (Couve manteiga refogada na pasta de amendoim)</p>

            <p>– MAFÉ veg : Este prato fervido do Mali é uma das especialidades africanas mais populares. Leva o nome da pasta de amendoim que o compõe. Se pode ser preparado com carne, e principalmente com frango, esta receita de mafé vegetariano é feita com cenoura, batata e repolho branco.</p>
            <p>Observação : Item sujeito a disponibilidade do restaurante.</p>
            <span>R$ 39,90</span>
          </div>
          <div className="box" data-dia="Quarta-Feira">
            <h3>Quarta-Feira</h3>
            <p>– feijoada do chef ( Feijão preto com legumes e mix de cogumelos, temperados com azeite de dendê e especiarias) com farofa de banana da terra </p>

            <p>– arroz pilau (Arroz típico congolês, refogado com legumes em suco de gengibre e especiárias.)</p>

            <p>– nhoque de banana da terra acompanha molho de shimeji</p>

            <p>– MAFÉ veg : Este prato fervido do Mali é uma das especialidades africanas mais populares. Leva o nome da pasta de amendoim que o compõe. Se pode ser preparado com carne, e principalmente com frango, esta receita de mafé vegetariano é feita com cenoura, batata e repolho branco.</p>

            <p>– moqueca de banana da terra com farofa de amendoim</p>

            <p>– Sambusas : Pasteizinhos típicos africanos, recheados com vegetais. (assado)</p>

            <p>– kachori : Bolinhos feito à base de batatinha inglesa, shimeji e gengibre.  (frito).</p>
            <p>Observação : Item sujeito a disponibilidade do restaurante.</p>
            <span>R$ 39,90</span>
          </div>
          <div className="box" data-dia="Quinta-Feira" >
            <h3>Quinta-Feira</h3>
            <p>– fufu ( polenta tipica a base de fubá e água) com couve na mwamba (Couve manteiga refogada na pasta de amendoim)</p>

            <p>– Kwanga (massa cozida de mandica fermentada) com madesu (  Feijão branco temperado no dendê, tomate fresco e especiarias).</p>

            <p>– Nhoque de banana da terra ao molho de shimeji</p>

            <p>– MAFÉ veg : Este prato fervido do Mali é uma das especialidades africanas mais populares. Leva o nome da pasta de amendoim que o compõe. Se pode ser preparado com carne, e principalmente com frango, esta receita de mafé vegetariano é feita com cenoura, batata e repolho branco.</p>

            <p>– Acarajé com quiabo temperado  na pasta de amendoim</p>

            <p>– Sambusas : Pasteizinhos típicos africanos, recheados com vegetais. (assado)</p>
            <p>Observação : Item sujeito a disponibilidade no restaurante.</p>
            <span>R$ 39,90</span>
          </div>
          <div className="box" data-dia="Sexta-Feira">
            <p>Foi desenvolivo o esquema de rodizio com instuito que o cliente possa experimentar a vontade pratos variados do congolinaria</p>
            <h3>ORDEM DE LIBERAÇÃO DOS PRATOS</h3>
            <p>– fufu ( polenta tipica a base de fubá e água) com couve na mwamba (Couve manteiga refogada na pasta de amendoim)</p>

            <p>– moqueca de banana da terra com farofa de amendoim</p>

            <p>– arroz pilau (Arroz típico congolês, refogado com legumes em suco de gengibre e especiárias.)</p>

            <p>– espinafre com macarrão </p>

            <p>– Kwanga com madesu (  Feijão branco temperado no dendê, tomate fresco e especiarias) </p>

            <p>– kachori : Bolinhos feito à base de batatinha de inglesa, shimeji e gengibre.  (frito).</p>
            <p>Observação : Item sujeito a disponibilidade do restaurante.</p>
            <span>R$ 39,90</span>
          </div>
          <div className="box" data-dia="Sábado">
            <h3>Sábado</h3>
            <p>– fufu ( polenta tipica a base de fubá e água) com couve na mwamba (Couve manteiga refogada na pasta de amendoim)</p>

            <p>– feijoada do chef com farofa de banana da terra </p>

            <p>– arroz pilau (Arroz típico congolês, refogado com legumes em suco de gengibre e especiárias.)</p>

            <p>– nhoque de banana da terra </p>

            <p>– MAFÉ veg : Este prato fervido do Mali é uma das especialidades africanas mais populares. Leva o nome da pasta de amendoim que o compõe. Se pode ser preparado com carne, e principalmente com frango, esta receita de mafé vegetariano é feita com cenoura, batata e repolho branco.</p>

            <p>– Sambusas : Pasteizinhos típicos africanos, recheados com vegetais. (assado)</p>

            <p>– kachori : Bolinhos feito à base de batatinha de inglesa, shimeji e gengibre.  (frito).</p>
            <p>Observação : Item sujeito a disponibilidade do restaurante.</p>
            <span>R$ 39,90</span>
          </div>
          <div className="box" data-dia="Domingo">
            <h3>Domingo</h3>
            <p>– Acarajé acompanha quiabo temperado  na pasta de amendoim</p>

            <p>– arroz com coco </p>

            <p>– macarrão de arroz ( sem glúten) com creme branco de sementes de girassol e epinafre</p>

            <p>– nhoque de banana da terra com molho de shimeji</p>

            <p>– Sambusas : Pasteizinhos típicos africanos, recheados com vegetais. (assado)</p>


            <p>– kachori : Bolinhos feito à base de batatinha de inglesa, shimeji e gengibre.  (frito).</p>
            <p>– fufu ( polenta tipica a base de fubá e água) com couve na mwamba (Couve manteiga refogada na pasta de amendoim)</p>

            <p>– moqueca de banana da terra acompanha farofa de amendoim</p>

            <p>Observação : Item sujeito a disponibilidade no restaurante.</p>
            <span>R$ 39,90</span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
